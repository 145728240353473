<template>
    <div id="markdown-container">
        <div id="md-show" v-html="compiledMarkdown"></div>
        <div id="mermaid-render-container" style="visibility: hidden"></div>
    </div>
</template>

<script>
    import { defineComponent } from 'vue';
    import marked from 'marked';
    import Mermaid from 'mermaid';
    import 'katex/dist/katex.css';
    import hljs from 'highlight.js';
    import 'highlight.js/styles/atom-one-light.css';
    import {randLowercaseString} from "@/utils/random";
    import renderMathInElement from "katex/dist/contrib/auto-render";

    const katexCharMacros = {
        "\\backslashbackslash": "\\\\",
        "\\’": "\\'",
        "\\‘": "\\`",
        "\\”": "\\\"", // render as: \"
        "’": "'",
        "\\backquote": "`",
        "\\overbar": "\\=",
        "\\backslashdot": "\\.",
        "\\backslashtilde": "\\~",
        "\\tilde": "~",
        "\\backslashem": "\\!",
        "\\backslashsharp": "\\#",
        "\\backslashpercent": "\\%",
        "\\backslashand": "\\&",
        "\\backslashleftparentheses": "\\(",
        "\\backslashrightparentheses": "\\)",
        "\\backslashcomma": "\\,",
        "\\backslashcolon": "\\:",
        "\\backslashsemicolon": "\\;",
        "\\backslashunderline": "\\_",
        "\\backslashright": "\\>",
        "\\backslashbraceopen": "\\{",
        "\\backslashbraceclose": "\\}",
        "\\backslashcaret": "\\^"
    };

    const katexCharsToConvert = [
        ["\\backslashbackslash ", new RegExp(/\\\\/, 'g')],
        ["\\’", new RegExp(/\\'/, 'g')],
        ["\\‘", new RegExp(/\\`/, 'g')],
        ["\\”", new RegExp(/\\"/, 'g')], // render as: \"
        ["’", new RegExp(/'/, 'g')],
        ["\\backquote ", new RegExp(/`/, 'g')],
        ["\\overbar ", new RegExp(/\\=/, 'g')],
        ["\\backslashdot ", new RegExp(/\\\./, 'g')],
        ["\\backslashtilde ", new RegExp(/\\~/, 'g')],
        ["\\tilde ", new RegExp(/~/, 'g')],
        ["\\backslashem ", new RegExp(/\\!/, 'g')],
        ["\\backslashsharp ", new RegExp(/\\#/, 'g')],
        ["\\backslashpercent ", new RegExp(/\\%/, 'g')],
        ["\\backslashand ", new RegExp(/\\&/, 'g')],
        ["\\backslashleftparentheses ", new RegExp(/\\\(/, 'g')],
        ["\\backslashrightparentheses ", new RegExp(/\\\)/, 'g')],
        ["\\backslashcomma ", new RegExp(/\\,/, 'g')],
        ["\\backslashcolon ", new RegExp(/\\:/, 'g')],
        ["\\backslashsemicolon ", new RegExp(/\\;/, 'g')],
        ["\\backslashunderline ", new RegExp(/\\_/, 'g')],
        ["\\backslashright ", new RegExp(/\\>/, 'g')],
        ["\\backslashbraceopen ", new RegExp(/\\\{/, 'g')],
        ["\\backslashbraceclose ", new RegExp(/\\}/, 'g')],
        ["\\backslashcaret ", new RegExp(/\\\^/, 'g')]
    ];

    export default defineComponent({
        name: "ContentShow",
        props: {
            content: String
        },
        created() {
            Mermaid.initialize({startOnLoad:false});
        },
        methods: {
            convertKatexSpecialChars(src) {
                const cap = src.match(/\$+[^\n]+?\$+/mg);
                if (cap) {
                    return cap.reduce((pre, cur) => {
                        return pre.replace(cur, () => {
                            return katexCharsToConvert.reduce((preMath, entry) => {
                                return preMath.replaceAll(entry[1], entry[0]);
                            }, cur);
                        });
                    }, src);
                }
                return src;
            },
            autoRenderMath() {
                const mdComponent = document.getElementById("md-show");
                if (mdComponent) {
                    renderMathInElement(mdComponent, {
                        // customised options
                        // • auto-render specific keys, e.g.:
                        delimiters: [
                            {left: '$$', right: '$$', display: true},
                            {left: '$', right: '$', display: false},
                            {left: "\\(", right: "\\)", display: false},
                            {left: "\\begin{equation}", right: "\\end{equation}", display: true},
                            {left: "\\begin{align}", right: "\\end{align}", display: true},
                            {left: "\\begin{alignat}", right: "\\end{alignat}", display: true},
                            {left: "\\begin{gather}", right: "\\end{gather}", display: true},
                            {left: "\\begin{CD}", right: "\\end{CD}", display: true},
                            {left: "\\[", right: "\\]", display: true}
                        ],
                        // • rendering keys, e.g.:
                        throwOnError : false,
                        macros: katexCharMacros
                    });
                }
            }
        },
        mounted() {
            const supportDiagram = [
                'mermaid',
                'flowchart',
                'sequence',
                'class',
                'state',
                'er',
                'journey',
                'gantt',
                'pie',
                'requirement'
            ];
            const container = document.getElementById("mermaid-render-container");
            const mermaidRenderCb = function(svgGraph){
                // console.log(svgGraph);
            };
            const preRenderMermaid = function(graphDefinition, idPostfix) {
                try {
                    if (Mermaid.parse(graphDefinition)) {
                        return Mermaid.render('mermaid-' + idPostfix, graphDefinition, mermaidRenderCb, container);
                    } else {
                        return null;
                    }
                } catch (e) {
                    return null;
                }
            };
            const renderer = {
                code(code, language) {
                    if (supportDiagram.includes(language)) {
                        const idPostfix = new Date().getTime() + randLowercaseString(10);
                        const mermaidRenderText = preRenderMermaid(code, idPostfix);
                        if (mermaidRenderText) {
                            return `<div class="mermaid" data-processed="true">${mermaidRenderText}</div>`;
                        } else {
                            return false;
                        }
                    } else if (language === 'math') {
                        return "$$" + code + "$$";
                    } else {
                        return false;
                    }
                },
            };
            marked.setOptions({
                highlight: function(code, lang) {
                    if (typeof lang === 'undefined' || lang === '') {
                        return hljs.highlightAuto(code).value;
                    } else {
                        const language = hljs.getLanguage(lang) ? lang : 'plaintext';
                        return hljs.highlight(code, { language }).value;
                    }
                },
            });
            marked.use({ renderer });
        },
        watch: {
            compiledMarkdown(value, old) {
                setTimeout(() => {
                    this.autoRenderMath();
                }, 50);
            }
        },
        computed: {
            compiledMarkdown() {
                const mkText = this.convertKatexSpecialChars(this.content);
                return marked.parse(mkText, {
                    // sanitize: true,
                    breaks: true,
                    smartypants: true,
                    xhtml: true
                });
            }
        }

    })
</script>

<style lang="less">
    #markdown-container {
        width: 100%;
        height: 100%;
        vertical-align: top;
        box-sizing: border-box;
        padding: 0 20px;
        text-align: justify;
        line-height: 1.8;
        font-family: -apple-system, BlinkMacSystemFont, system-ui, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", Arial;
        font-size: 15px;

        p {
            margin: 16px 0;
        }

        li {
            font-size: 16px;
            font-weight: 400;
        }

        table {
            border-collapse: collapse;

            thead tr th, tbody tr td {
                border: 1px solid #e3e3e3;
                padding: 6px 14px;
            }
            thead {
                tr {
                    th {
                        font-weight: bold;
                    }
                }
            }
            tbody {
                tr:nth-child(2n) {
                    background-color: #fafafa;
                }
            }
        }

        code {
            padding: 3px 6px;
            background-color: #eff0f1;
            border-radius: 5px;
        }

        pre {
            code {
                color: #42576d;
                background-color: #eff0f1;
            }
            padding: 16px;
            overflow: auto;
            background-color: #eff0f1;
            border-radius: 5px;
        }

        blockquote {
            margin: 0 0 24px;
            padding: 0 0 0 12px;
            border-left: 4px solid #d4e6f5;
            color: #7e8690;
        }

        img {
            max-width: 100%;
        }
    }
</style>