const LOWERCASE_CHARS = [
    '0', '1', '2', '3', '4', '5','6', '7', '8',
    '9', 'a', 'b', 'c', 'd', 'e','f', 'g', 'h',
    'i', 'j', 'k', 'l', 'm', 'n','o', 'p', 'q',
    'r', 's', 't', 'u', 'v', 'w','x', 'y', 'z'
];

export function randomNumberString(length: number) {
    if (length < 1) {
        return ''
    } else {
        let str = ''
        for (let i = 0; i < length; i++) {
            str += Math.floor(Math.random() * 10)
        }
        return str;
    }
}

export function randLowercaseString(length: number) {
    if (length < 1) {
        return ''
    } else {
        let str = ''
        for (let i = 0; i < length; i++) {
            str += LOWERCASE_CHARS[Math.floor(Math.random() * 36)];
        }
        return str;
    }
}